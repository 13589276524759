/* eslint-disable */
import React from 'react';

const TwitterIcon = () => {
  return (
    <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.4836 0.00902672C19.624 0.615383 18.6722 1.07915 17.6649 1.38246C17.1243 0.760836 16.4058 0.320242 15.6066 0.120272C14.8074 -0.0796988 13.9661 -0.0293975 13.1964 0.264372C12.4267 0.558142 11.7658 1.08121 11.3031 1.76282C10.8404 2.44444 10.5982 3.25172 10.6092 4.07548V4.97315C9.03171 5.01405 7.46854 4.66418 6.05896 3.95469C4.64938 3.2452 3.43714 2.19812 2.53021 0.906698C2.53021 0.906698 -1.06048 8.98574 7.01856 12.5764C5.16983 13.8313 2.96751 14.4606 0.734863 14.3718C8.81391 18.8601 18.6883 14.3718 18.6883 4.04855C18.6875 3.7985 18.6634 3.54908 18.6165 3.30348C19.5326 2.39997 20.1792 1.25923 20.4836 0.00902672Z" fill="white"/>
    </svg>
  );
};

// eslint-disable-next-line import/no-default-export
export default TwitterIcon;
